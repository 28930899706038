<template>
  <div class="header-main-component">
    <!-- <div
      class="header-logo pointer text-center pt-2"
      @click="redirect('/home')"
    >
      <img lazy-src="logo" :src="topLogo" />
    </div> -->
    <div class="header-wrapper d-flex justify-center">
      <div class="container ma-0 px-4">
        <div class="d-flex align-center">
          <!-- menu icon -->
          <fa-icon
            @click.stop="showDrawer()"
            style="font-size: 28px"
            :icon="['fas', 'bars']"
            class="pointer"
          ></fa-icon>

          <!-- menu switcher -->
          <div class="ma-3">
            <Bots />
            <!-- <span>{{ currentUser ? activeMenu : "Menu" }}</span> -->
          </div>
        </div>
        <div class="ml-auto">
          <!-- Bots -->
          <!-- <Bots /> -->
        </div>
      </div>
    </div>

    <div class="ma-3" v-if="getCurrentRoute != 'Home'">
      <span class="font-weight-bold">{{
        currentUser ? activeMenu : "Menu"
      }}</span>
    </div>
  </div>
</template>
<script>
import logoImage from "@/assets/logos/pinoybot-logo.png";
import topLogoImage from "@/assets/logos/pinoybot-logo-120.png";
import { mapGetters } from "vuex";
import Bots from "@/components/bot_profile/bots.vue";

export default {
  props: {
    drawer: Boolean,
  },
  data: () => ({
    mainDrawer: true,
    menus: 0,
  }),
  components: {
    Bots,
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),

    topLogo() {
      return topLogoImage;
    },
    logo() {
      return logoImage;
    },
    getCurrentRoute() {
      return this.$route.name;
    },
    activeMenu() {
      const _route = this.$route.name;
      if (_route == "DashboardPlayground") {
        return "Playground";
      } else if (_route == "DashboardManageBots") {
        return "Manage Bots";
      } else if (
        _route == "DashboardHistories" ||
        _route == "DashboardHistories2" ||
        _route == "DashboardHistories3"
      ) {
        return "Histories";
      } else if (_route == "DashboardSettings") {
        return "Settings";
      } else if (_route == "DashboardProfile") {
        return "Profile";
      } else if (_route == "DashboardSubmitBug") {
        return "Submit a Bug";
      } else {
        return "Home";
      }
    },
  },
  methods: {
    redirect(url) {
      this.$router.push(url);
    },
    showDrawer() {
      this.$emit("showDrawer", !this.drawer);
    },
    showHistoriesDrawer() {
      this.$emit("showDrawerHistories", !this.drawer);
    },
  },
};
</script>
<style lang="scss">
.menu-list {
  .v-item-group {
    display: flex;
  }
}
.header-main-component {
  position: relative;
  background: #fff;
  .header-wrapper {
    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%);
    background: #fff;
    .container {
      margin: 0 auto;
      padding: 5px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
